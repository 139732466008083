import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import history from './util/history';

function render(action, location) {
  ReactDOM.render(<App action={action} location={location} />, document.getElementById('root'));
}

history.listen((location, action) => {
  // location is an object like window.location
  render(action, location);
});

render(null, history.location)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
