import React from 'react';
import Home from './Home';
import SignUp from './SignUp';

function App({ location }) {
  if (location.pathname === '/sign-up/') {
    return (
      <SignUp />
    );
  }

  return (
    <Home />
  );
}

export default App;
